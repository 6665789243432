import {ExamplePlan} from './';

const Sample: ExamplePlan = {
  sql: `
SELECT *
FROM pgbench_accounts
JOIN pgbench_branches USING (bid)
LEFT JOIN pg bench_tellers USING (bid)
WHERE pgbench_accounts.aid < 1000000
ORDER BY pgbench_accounts.abalance DESC
LIMIT 100
`,
  queries: [
    {
      'Plan': {
        'Node Type': 'Limit',
        'Parallel Aware': false,
        'Startup Cost': 258327.9,
        'Total Cost': 258339.57,
        'Plan Rows': 100,
        'Plan Width': 809,
        'Actual Startup Time': 4551.061,
        'Actual Total Time': 4551.104,
        'Actual Rows': 100,
        'Actual Loops': 1,
        'Output': [
          'pgbench_accounts.bid',
          'pgbench_accounts.aid',
          'pgbench_accounts.abalance',
          'pgbench_accounts.filler',
          'pgbench_branches.bbalance',
          'pgbench_branches.filler',
          'pgbench_tellers.tid',
          'pgbench_tellers.tbalance',
          'pgbench_tellers.filler',
          'pgbench_accounts.abalance',
        ],
        'Shared Hit Blocks': 1640,
        'Shared Read Blocks': 6619,
        'Shared Dirtied Blocks': 0,
        'Shared Written Blocks': 0,
        'Local Hit Blocks': 0,
        'Local Read Blocks': 0,
        'Local Dirtied Blocks': 0,
        'Local Written Blocks': 0,
        'Temp Read Blocks': 0,
        'Temp Written Blocks': 0,
        'I/O Read Time': 1263.549,
        'I/O Write Time': 0.0,
        'Plans': [
          {
            'Node Type': 'Gather Merge',
            'Parent Relationship': 'Outer',
            'Parallel Aware': false,
            'Startup Cost': 258327.9,
            'Total Cost': 1268616.03,
            'Plan Rows': 8659008,
            'Plan Width': 809,
            'Actual Startup Time': 4010.161,
            'Actual Total Time': 4181.991,
            'Actual Rows': 100,
            'Actual Loops': 1,
            'Output': [
              'pgbench_accounts.bid',
              'pgbench_accounts.aid',
              'pgbench_accounts.abalance',
              'pgbench_accounts.filler',
              'pgbench_branches.bbalance',
              'pgbench_branches.filler',
              'pgbench_tellers.tid',
              'pgbench_tellers.tbalance',
              'pgbench_tellers.filler',
              'pgbench_accounts.abalance',
            ],
            'Workers Planned': 2,
            'Workers Launched': 2,
            'Shared Hit Blocks': 1756,
            'Shared Read Blocks': 17515,
            'Shared Dirtied Blocks': 0,
            'Shared Written Blocks': 0,
            'Local Hit Blocks': 0,
            'Local Read Blocks': 0,
            'Local Dirtied Blocks': 0,
            'Local Written Blocks': 0,
            'Temp Read Blocks': 0,
            'Temp Written Blocks': 0,
            'I/O Read Time': 3649.383,
            'I/O Write Time': 0.0,
            'Plans': [
              {
                'Node Type': 'Sort',
                'Parent Relationship': 'Outer',
                'Parallel Aware': false,
                'Startup Cost': 257327.87,
                'Total Cost': 268151.63,
                'Plan Rows': 4329504,
                'Plan Width': 809,
                'Actual Startup Time': 3457.5,
                'Actual Total Time': 3457.51,
                'Actual Rows': 100,
                'Actual Loops': 3,
                'Output': [
                  'pgbench_accounts.bid',
                  'pgbench_accounts.aid',
                  'pgbench_accounts.abalance',
                  'pgbench_accounts.filler',
                  'pgbench_branches.bbalance',
                  'pgbench_branches.filler',
                  'pgbench_tellers.tid',
                  'pgbench_tellers.tbalance',
                  'pgbench_tellers.filler',
                  'pgbench_accounts.abalance',
                ],
                'Sort Key': ['pgbench_accounts.abalance DESC'],
                'Sort Method': 'top-N heapsort',
                'Sort Space Used': 51,
                'Sort Space Type': 'Memory',
                // Duplicate:
                // see https://www.postgresql.org/message-id/flat/16487-c253609c5d095ff1%40postgresql.org
                //"Workers": [
                //{
                //"Worker Number": 0,
                //"Sort Method": "top-N heapsort",
                //"Sort Space Used": 51,
                //"Sort Space Type": "Memory"
                //},
                //{
                //"Worker Number": 1,
                //"Sort Method": "top-N heapsort",
                //"Sort Space Used": 51,
                //"Sort Space Type": "Memory"
                //}
                //],
                'Shared Hit Blocks': 1756,
                'Shared Read Blocks': 17515,
                'Shared Dirtied Blocks': 0,
                'Shared Written Blocks': 0,
                'Local Hit Blocks': 0,
                'Local Read Blocks': 0,
                'Local Dirtied Blocks': 0,
                'Local Written Blocks': 0,
                'Temp Read Blocks': 0,
                'Temp Written Blocks': 0,
                'I/O Read Time': 3649.383,
                'I/O Write Time': 0.0,
                'Workers': [
                  {
                    'Worker Number': 0,
                    'Actual Startup Time': 3181.625,
                    'Actual Total Time': 3181.634,
                    'Actual Rows': 100,
                    'Actual Loops': 1,
                    'Shared Hit Blocks': 58,
                    'Shared Read Blocks': 5484,
                    'Shared Dirtied Blocks': 0,
                    'Shared Written Blocks': 0,
                    'Local Hit Blocks': 0,
                    'Local Read Blocks': 0,
                    'Local Dirtied Blocks': 0,
                    'Local Written Blocks': 0,
                    'Temp Read Blocks': 0,
                    'Temp Written Blocks': 0,
                    'I/O Read Time': 1185.529,
                    'I/O Write Time': 0.0,
                  },
                  {
                    'Worker Number': 1,
                    'Actual Startup Time': 3181.265,
                    'Actual Total Time': 3181.275,
                    'Actual Rows': 100,
                    'Actual Loops': 1,
                    'Shared Hit Blocks': 58,
                    'Shared Read Blocks': 5412,
                    'Shared Dirtied Blocks': 0,
                    'Shared Written Blocks': 0,
                    'Local Hit Blocks': 0,
                    'Local Read Blocks': 0,
                    'Local Dirtied Blocks': 0,
                    'Local Written Blocks': 0,
                    'Temp Read Blocks': 0,
                    'Temp Written Blocks': 0,
                    'I/O Read Time': 1200.305,
                    'I/O Write Time': 0.0,
                  },
                ],
                'Plans': [
                  {
                    'Node Type': 'Hash Join',
                    'Parent Relationship': 'Outer',
                    'Parallel Aware': false,
                    'Join Type': 'Left',
                    'Startup Cost': 123.56,
                    'Total Cost': 91857.34,
                    'Plan Rows': 4329504,
                    'Plan Width': 809,
                    'Actual Startup Time': 34.091,
                    'Actual Total Time': 2500.941,
                    'Actual Rows': 3333330,
                    'Actual Loops': 3,
                    'Output': [
                      'pgbench_accounts.bid',
                      'pgbench_accounts.aid',
                      'pgbench_accounts.abalance',
                      'pgbench_accounts.filler',
                      'pgbench_branches.bbalance',
                      'pgbench_branches.filler',
                      'pgbench_tellers.tid',
                      'pgbench_tellers.tbalance',
                      'pgbench_tellers.filler',
                      'pgbench_accounts.abalance',
                    ],
                    'Inner Unique': false,
                    'Hash Cond': '(pgbench_accounts.bid = pgbench_tellers.bid)',
                    'Shared Hit Blocks': 1742,
                    'Shared Read Blocks': 17515,
                    'Shared Dirtied Blocks': 0,
                    'Shared Written Blocks': 0,
                    'Local Hit Blocks': 0,
                    'Local Read Blocks': 0,
                    'Local Dirtied Blocks': 0,
                    'Local Written Blocks': 0,
                    'Temp Read Blocks': 0,
                    'Temp Written Blocks': 0,
                    'I/O Read Time': 3649.383,
                    'I/O Write Time': 0.0,
                    'Workers': [
                      {
                        'Worker Number': 0,
                        'Actual Startup Time': 43.96,
                        'Actual Total Time': 2327.841,
                        'Actual Rows': 2866650,
                        'Actual Loops': 1,
                        'Shared Hit Blocks': 51,
                        'Shared Read Blocks': 5484,
                        'Shared Dirtied Blocks': 0,
                        'Shared Written Blocks': 0,
                        'Local Hit Blocks': 0,
                        'Local Read Blocks': 0,
                        'Local Dirtied Blocks': 0,
                        'Local Written Blocks': 0,
                        'Temp Read Blocks': 0,
                        'Temp Written Blocks': 0,
                        'I/O Read Time': 1185.529,
                        'I/O Write Time': 0.0,
                      },
                      {
                        'Worker Number': 1,
                        'Actual Startup Time': 42.334,
                        'Actual Total Time': 2334.298,
                        'Actual Rows': 2829180,
                        'Actual Loops': 1,
                        'Shared Hit Blocks': 51,
                        'Shared Read Blocks': 5412,
                        'Shared Dirtied Blocks': 0,
                        'Shared Written Blocks': 0,
                        'Local Hit Blocks': 0,
                        'Local Read Blocks': 0,
                        'Local Dirtied Blocks': 0,
                        'Local Written Blocks': 0,
                        'Temp Read Blocks': 0,
                        'Temp Written Blocks': 0,
                        'I/O Read Time': 1200.305,
                        'I/O Write Time': 0.0,
                      },
                    ],
                    'Plans': [
                      {
                        'Node Type': 'Hash Join',
                        'Parent Relationship': 'Outer',
                        'Parallel Aware': false,
                        'Join Type': 'Inner',
                        'Startup Cost': 11.56,
                        'Total Cost': 41924.42,
                        'Plan Rows': 435061,
                        'Plan Width': 457,
                        'Actual Startup Time': 30.373,
                        'Actual Total Time': 1526.899,
                        'Actual Rows': 333333,
                        'Actual Loops': 3,
                        'Output': [
                          'pgbench_accounts.bid',
                          'pgbench_accounts.aid',
                          'pgbench_accounts.abalance',
                          'pgbench_accounts.filler',
                          'pgbench_branches.bbalance',
                          'pgbench_branches.filler',
                        ],
                        'Inner Unique': true,
                        'Hash Cond': '(pgbench_accounts.bid = pgbench_branches.bid)',
                        'Shared Hit Blocks': 1698,
                        'Shared Read Blocks': 17493,
                        'Shared Dirtied Blocks': 0,
                        'Shared Written Blocks': 0,
                        'Local Hit Blocks': 0,
                        'Local Read Blocks': 0,
                        'Local Dirtied Blocks': 0,
                        'Local Written Blocks': 0,
                        'Temp Read Blocks': 0,
                        'Temp Written Blocks': 0,
                        'I/O Read Time': 3644.098,
                        'I/O Write Time': 0.0,
                        'Workers': [
                          {
                            'Worker Number': 0,
                            'Actual Startup Time': 42.043,
                            'Actual Total Time': 1480.352,
                            'Actual Rows': 286665,
                            'Actual Loops': 1,
                            'Shared Hit Blocks': 29,
                            'Shared Read Blocks': 5484,
                            'Shared Dirtied Blocks': 0,
                            'Shared Written Blocks': 0,
                            'Local Hit Blocks': 0,
                            'Local Read Blocks': 0,
                            'Local Dirtied Blocks': 0,
                            'Local Written Blocks': 0,
                            'Temp Read Blocks': 0,
                            'Temp Written Blocks': 0,
                            'I/O Read Time': 1185.529,
                            'I/O Write Time': 0.0,
                          },
                          {
                            'Worker Number': 1,
                            'Actual Startup Time': 40.381,
                            'Actual Total Time': 1492.152,
                            'Actual Rows': 282918,
                            'Actual Loops': 1,
                            'Shared Hit Blocks': 29,
                            'Shared Read Blocks': 5412,
                            'Shared Dirtied Blocks': 0,
                            'Shared Written Blocks': 0,
                            'Local Hit Blocks': 0,
                            'Local Read Blocks': 0,
                            'Local Dirtied Blocks': 0,
                            'Local Written Blocks': 0,
                            'Temp Read Blocks': 0,
                            'Temp Written Blocks': 0,
                            'I/O Read Time': 1200.305,
                            'I/O Write Time': 0.0,
                          },
                        ],
                        'Plans': [
                          {
                            'Node Type': 'Index Scan',
                            'Parent Relationship': 'Outer',
                            'Parallel Aware': true,
                            'Scan Direction': 'Forward',
                            'Index Name': 'pgbench_accounts_pkey',
                            'Relation Name': 'pgbench_accounts',
                            'Schema': 'public',
                            'Alias': 'pgbench_accounts',
                            'Startup Cost': 0.56,
                            'Total Cost': 40759.28,
                            'Plan Rows': 435061,
                            'Plan Width': 97,
                            'Actual Startup Time': 2.249,
                            'Actual Total Time': 1338.377,
                            'Actual Rows': 333333,
                            'Actual Loops': 3,
                            'Output': [
                              'pgbench_accounts.aid',
                              'pgbench_accounts.bid',
                              'pgbench_accounts.abalance',
                              'pgbench_accounts.filler',
                            ],
                            'Index Cond': '(pgbench_accounts.aid < 1000000)',
                            'Rows Removed by Index Recheck': 0,
                            'Shared Hit Blocks': 1640,
                            'Shared Read Blocks': 17491,
                            'Shared Dirtied Blocks': 0,
                            'Shared Written Blocks': 0,
                            'Local Hit Blocks': 0,
                            'Local Read Blocks': 0,
                            'Local Dirtied Blocks': 0,
                            'Local Written Blocks': 0,
                            'Temp Read Blocks': 0,
                            'Temp Written Blocks': 0,
                            'I/O Read Time': 3641.806,
                            'I/O Write Time': 0.0,
                            'Workers': [
                              {
                                'Worker Number': 0,
                                'Actual Startup Time': 0.362,
                                'Actual Total Time': 1298.07,
                                'Actual Rows': 286665,
                                'Actual Loops': 1,
                                'Shared Hit Blocks': 0,
                                'Shared Read Blocks': 5484,
                                'Shared Dirtied Blocks': 0,
                                'Shared Written Blocks': 0,
                                'Local Hit Blocks': 0,
                                'Local Read Blocks': 0,
                                'Local Dirtied Blocks': 0,
                                'Local Written Blocks': 0,
                                'Temp Read Blocks': 0,
                                'Temp Written Blocks': 0,
                                'I/O Read Time': 1185.529,
                                'I/O Write Time': 0.0,
                              },
                              {
                                'Worker Number': 1,
                                'Actual Startup Time': 0.204,
                                'Actual Total Time': 1312.106,
                                'Actual Rows': 282918,
                                'Actual Loops': 1,
                                'Shared Hit Blocks': 0,
                                'Shared Read Blocks': 5412,
                                'Shared Dirtied Blocks': 0,
                                'Shared Written Blocks': 0,
                                'Local Hit Blocks': 0,
                                'Local Read Blocks': 0,
                                'Local Dirtied Blocks': 0,
                                'Local Written Blocks': 0,
                                'Temp Read Blocks': 0,
                                'Temp Written Blocks': 0,
                                'I/O Read Time': 1200.305,
                                'I/O Write Time': 0.0,
                              },
                            ],
                          },
                          {
                            'Node Type': 'Hash',
                            'Parent Relationship': 'Inner',
                            'Parallel Aware': false,
                            'Startup Cost': 6.0,
                            'Total Cost': 6.0,
                            'Plan Rows': 400,
                            'Plan Width': 364,
                            'Actual Startup Time': 28.039,
                            'Actual Total Time': 28.039,
                            'Actual Rows': 400,
                            'Actual Loops': 3,
                            'Output': ['pgbench_branches.bbalance', 'pgbench_branches.filler', 'pgbench_branches.bid'],
                            'Hash Buckets': 1024,
                            'Original Hash Buckets': 1024,
                            'Hash Batches': 1,
                            'Original Hash Batches': 1,
                            'Peak Memory Usage': 24,
                            'Shared Hit Blocks': 4,
                            'Shared Read Blocks': 2,
                            'Shared Dirtied Blocks': 0,
                            'Shared Written Blocks': 0,
                            'Local Hit Blocks': 0,
                            'Local Read Blocks': 0,
                            'Local Dirtied Blocks': 0,
                            'Local Written Blocks': 0,
                            'Temp Read Blocks': 0,
                            'Temp Written Blocks': 0,
                            'I/O Read Time': 2.292,
                            'I/O Write Time': 0.0,
                            'Workers': [
                              {
                                'Worker Number': 0,
                                'Actual Startup Time': 41.554,
                                'Actual Total Time': 41.554,
                                'Actual Rows': 400,
                                'Actual Loops': 1,
                                'Shared Hit Blocks': 2,
                                'Shared Read Blocks': 0,
                                'Shared Dirtied Blocks': 0,
                                'Shared Written Blocks': 0,
                                'Local Hit Blocks': 0,
                                'Local Read Blocks': 0,
                                'Local Dirtied Blocks': 0,
                                'Local Written Blocks': 0,
                                'Temp Read Blocks': 0,
                                'Temp Written Blocks': 0,
                                'I/O Read Time': 0.0,
                                'I/O Write Time': 0.0,
                              },
                              {
                                'Worker Number': 1,
                                'Actual Startup Time': 40.074,
                                'Actual Total Time': 40.075,
                                'Actual Rows': 400,
                                'Actual Loops': 1,
                                'Shared Hit Blocks': 2,
                                'Shared Read Blocks': 0,
                                'Shared Dirtied Blocks': 0,
                                'Shared Written Blocks': 0,
                                'Local Hit Blocks': 0,
                                'Local Read Blocks': 0,
                                'Local Dirtied Blocks': 0,
                                'Local Written Blocks': 0,
                                'Temp Read Blocks': 0,
                                'Temp Written Blocks': 0,
                                'I/O Read Time': 0.0,
                                'I/O Write Time': 0.0,
                              },
                            ],
                            'Plans': [
                              {
                                'Node Type': 'Seq Scan',
                                'Parent Relationship': 'Outer',
                                'Parallel Aware': false,
                                'Relation Name': 'pgbench_branches',
                                'Schema': 'public',
                                'Alias': 'pgbench_branches',
                                'Startup Cost': 0.0,
                                'Total Cost': 6.0,
                                'Plan Rows': 400,
                                'Plan Width': 364,
                                'Actual Startup Time': 27.623,
                                'Actual Total Time': 27.939,
                                'Actual Rows': 400,
                                'Actual Loops': 3,
                                'Output': [
                                  'pgbench_branches.bbalance',
                                  'pgbench_branches.filler',
                                  'pgbench_branches.bid',
                                ],
                                'Shared Hit Blocks': 4,
                                'Shared Read Blocks': 2,
                                'Shared Dirtied Blocks': 0,
                                'Shared Written Blocks': 0,
                                'Local Hit Blocks': 0,
                                'Local Read Blocks': 0,
                                'Local Dirtied Blocks': 0,
                                'Local Written Blocks': 0,
                                'Temp Read Blocks': 0,
                                'Temp Written Blocks': 0,
                                'I/O Read Time': 2.292,
                                'I/O Write Time': 0.0,
                                'Workers': [
                                  {
                                    'Worker Number': 0,
                                    'Actual Startup Time': 41.347,
                                    'Actual Total Time': 41.434,
                                    'Actual Rows': 400,
                                    'Actual Loops': 1,
                                    'Shared Hit Blocks': 2,
                                    'Shared Read Blocks': 0,
                                    'Shared Dirtied Blocks': 0,
                                    'Shared Written Blocks': 0,
                                    'Local Hit Blocks': 0,
                                    'Local Read Blocks': 0,
                                    'Local Dirtied Blocks': 0,
                                    'Local Written Blocks': 0,
                                    'Temp Read Blocks': 0,
                                    'Temp Written Blocks': 0,
                                    'I/O Read Time': 0.0,
                                    'I/O Write Time': 0.0,
                                  },
                                  {
                                    'Worker Number': 1,
                                    'Actual Startup Time': 39.903,
                                    'Actual Total Time': 39.982,
                                    'Actual Rows': 400,
                                    'Actual Loops': 1,
                                    'Shared Hit Blocks': 2,
                                    'Shared Read Blocks': 0,
                                    'Shared Dirtied Blocks': 0,
                                    'Shared Written Blocks': 0,
                                    'Local Hit Blocks': 0,
                                    'Local Read Blocks': 0,
                                    'Local Dirtied Blocks': 0,
                                    'Local Written Blocks': 0,
                                    'Temp Read Blocks': 0,
                                    'Temp Written Blocks': 0,
                                    'I/O Read Time': 0.0,
                                    'I/O Write Time': 0.0,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        'Node Type': 'Hash',
                        'Parent Relationship': 'Inner',
                        'Parallel Aware': false,
                        'Startup Cost': 62.0,
                        'Total Cost': 62.0,
                        'Plan Rows': 4000,
                        'Plan Width': 352,
                        'Actual Startup Time': 3.695,
                        'Actual Total Time': 3.695,
                        'Actual Rows': 4000,
                        'Actual Loops': 3,
                        'Output': [
                          'pgbench_tellers.tid',
                          'pgbench_tellers.tbalance',
                          'pgbench_tellers.filler',
                          'pgbench_tellers.bid',
                        ],
                        'Hash Buckets': 4096,
                        'Original Hash Buckets': 4096,
                        'Hash Batches': 1,
                        'Original Hash Batches': 1,
                        'Peak Memory Usage': 204,
                        'Shared Hit Blocks': 44,
                        'Shared Read Blocks': 22,
                        'Shared Dirtied Blocks': 0,
                        'Shared Written Blocks': 0,
                        'Local Hit Blocks': 0,
                        'Local Read Blocks': 0,
                        'Local Dirtied Blocks': 0,
                        'Local Written Blocks': 0,
                        'Temp Read Blocks': 0,
                        'Temp Written Blocks': 0,
                        'I/O Read Time': 5.286,
                        'I/O Write Time': 0.0,
                        'Workers': [
                          {
                            'Worker Number': 0,
                            'Actual Startup Time': 1.898,
                            'Actual Total Time': 1.898,
                            'Actual Rows': 4000,
                            'Actual Loops': 1,
                            'Shared Hit Blocks': 22,
                            'Shared Read Blocks': 0,
                            'Shared Dirtied Blocks': 0,
                            'Shared Written Blocks': 0,
                            'Local Hit Blocks': 0,
                            'Local Read Blocks': 0,
                            'Local Dirtied Blocks': 0,
                            'Local Written Blocks': 0,
                            'Temp Read Blocks': 0,
                            'Temp Written Blocks': 0,
                            'I/O Read Time': 0.0,
                            'I/O Write Time': 0.0,
                          },
                          {
                            'Worker Number': 1,
                            'Actual Startup Time': 1.934,
                            'Actual Total Time': 1.934,
                            'Actual Rows': 4000,
                            'Actual Loops': 1,
                            'Shared Hit Blocks': 22,
                            'Shared Read Blocks': 0,
                            'Shared Dirtied Blocks': 0,
                            'Shared Written Blocks': 0,
                            'Local Hit Blocks': 0,
                            'Local Read Blocks': 0,
                            'Local Dirtied Blocks': 0,
                            'Local Written Blocks': 0,
                            'Temp Read Blocks': 0,
                            'Temp Written Blocks': 0,
                            'I/O Read Time': 0.0,
                            'I/O Write Time': 0.0,
                          },
                        ],
                        'Plans': [
                          {
                            'Node Type': 'Seq Scan',
                            'Parent Relationship': 'Outer',
                            'Parallel Aware': false,
                            'Relation Name': 'pgbench_tellers',
                            'Schema': 'public',
                            'Alias': 'pgbench_tellers',
                            'Startup Cost': 0.0,
                            'Total Cost': 62.0,
                            'Plan Rows': 4000,
                            'Plan Width': 352,
                            'Actual Startup Time': 0.612,
                            'Actual Total Time': 2.64,
                            'Actual Rows': 4000,
                            'Actual Loops': 3,
                            'Output': [
                              'pgbench_tellers.tid',
                              'pgbench_tellers.tbalance',
                              'pgbench_tellers.filler',
                              'pgbench_tellers.bid',
                            ],
                            'Shared Hit Blocks': 44,
                            'Shared Read Blocks': 22,
                            'Shared Dirtied Blocks': 0,
                            'Shared Written Blocks': 0,
                            'Local Hit Blocks': 0,
                            'Local Read Blocks': 0,
                            'Local Dirtied Blocks': 0,
                            'Local Written Blocks': 0,
                            'Temp Read Blocks': 0,
                            'Temp Written Blocks': 0,
                            'I/O Read Time': 5.286,
                            'I/O Write Time': 0.0,
                            'Workers': [
                              {
                                'Worker Number': 0,
                                'Actual Startup Time': 0.036,
                                'Actual Total Time': 0.862,
                                'Actual Rows': 4000,
                                'Actual Loops': 1,
                                'Shared Hit Blocks': 22,
                                'Shared Read Blocks': 0,
                                'Shared Dirtied Blocks': 0,
                                'Shared Written Blocks': 0,
                                'Local Hit Blocks': 0,
                                'Local Read Blocks': 0,
                                'Local Dirtied Blocks': 0,
                                'Local Written Blocks': 0,
                                'Temp Read Blocks': 0,
                                'Temp Written Blocks': 0,
                                'I/O Read Time': 0.0,
                                'I/O Write Time': 0.0,
                              },
                              {
                                'Worker Number': 1,
                                'Actual Startup Time': 0.038,
                                'Actual Total Time': 0.905,
                                'Actual Rows': 4000,
                                'Actual Loops': 1,
                                'Shared Hit Blocks': 22,
                                'Shared Read Blocks': 0,
                                'Shared Dirtied Blocks': 0,
                                'Shared Written Blocks': 0,
                                'Local Hit Blocks': 0,
                                'Local Read Blocks': 0,
                                'Local Dirtied Blocks': 0,
                                'Local Written Blocks': 0,
                                'Temp Read Blocks': 0,
                                'Temp Written Blocks': 0,
                                'I/O Read Time': 0.0,
                                'I/O Write Time': 0.0,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      'Planning Time': 0.459,
      'Triggers': [],
      'JIT': {
        'Worker Number': -1,
        'Functions': 64,
        'Options': {
          'Inlining': false,
          'Optimization': false,
          'Expressions': true,
          'Deforming': true,
        },
        'Timing': {
          'Generation': 6.609,
          'Inlining': 0.0,
          'Optimization': 279.27,
          'Emission': 291.653,
          'Total': 577.532,
        },
      },
      'Execution Time': 4725.163,
    },
  ],
};
export default Sample;
