import {ExamplePlan} from './';

const Sample: ExamplePlan = {
  sql: `
/** This is a rather straight-forward plan, but the looped Materialize node suffers
from rounding issues, causing minor explain accounting issues that FlameExplain
fixes. */
EXPLAIN (ANALYZE, FORMAT JSON, VERBOSE, BUFFERS)
SELECT * FROM pg_indexes;
`,
  queries: [
    {
      'Plan': {
        'Node Type': 'Nested Loop',
        'Parallel Aware': false,
        'Join Type': 'Left',
        'Startup Cost': 19.62,
        'Total Cost': 40.91,
        'Plan Rows': 14,
        'Plan Width': 288,
        'Actual Startup Time': 0.411,
        'Actual Total Time': 10.316,
        'Actual Rows': 130,
        'Actual Loops': 1,
        'Output': ['n.nspname', 'c.relname', 'i.relname', 't.spcname', 'pg_get_indexdef(i.oid)'],
        'Inner Unique': true,
        'Join Filter': '(t.oid = i.reltablespace)',
        'Rows Removed by Join Filter': 242,
        'Shared Hit Blocks': 3350,
        'Shared Read Blocks': 0,
        'Shared Dirtied Blocks': 0,
        'Shared Written Blocks': 0,
        'Local Hit Blocks': 0,
        'Local Read Blocks': 0,
        'Local Dirtied Blocks': 0,
        'Local Written Blocks': 0,
        'Temp Read Blocks': 0,
        'Temp Written Blocks': 0,
        'Plans': [
          {
            'Node Type': 'Hash Join',
            'Parent Relationship': 'Outer',
            'Parallel Aware': false,
            'Join Type': 'Left',
            'Startup Cost': 19.62,
            'Total Cost': 39.5,
            'Plan Rows': 14,
            'Plan Width': 200,
            'Actual Startup Time': 0.322,
            'Actual Total Time': 1.223,
            'Actual Rows': 130,
            'Actual Loops': 1,
            'Output': ['c.relname', 'i.relname', 'i.oid', 'i.reltablespace', 'n.nspname'],
            'Inner Unique': true,
            'Hash Cond': '(c.relnamespace = n.oid)',
            'Shared Hit Blocks': 407,
            'Shared Read Blocks': 0,
            'Shared Dirtied Blocks': 0,
            'Shared Written Blocks': 0,
            'Local Hit Blocks': 0,
            'Local Read Blocks': 0,
            'Local Dirtied Blocks': 0,
            'Local Written Blocks': 0,
            'Temp Read Blocks': 0,
            'Temp Written Blocks': 0,
            'Plans': [
              {
                'Node Type': 'Nested Loop',
                'Parent Relationship': 'Outer',
                'Parallel Aware': false,
                'Join Type': 'Inner',
                'Startup Cost': 18.49,
                'Total Cost': 38.31,
                'Plan Rows': 14,
                'Plan Width': 140,
                'Actual Startup Time': 0.302,
                'Actual Total Time': 1.094,
                'Actual Rows': 130,
                'Actual Loops': 1,
                'Output': ['c.relname', 'c.relnamespace', 'i.relname', 'i.oid', 'i.reltablespace'],
                'Inner Unique': true,
                'Shared Hit Blocks': 406,
                'Shared Read Blocks': 0,
                'Shared Dirtied Blocks': 0,
                'Shared Written Blocks': 0,
                'Local Hit Blocks': 0,
                'Local Read Blocks': 0,
                'Local Dirtied Blocks': 0,
                'Local Written Blocks': 0,
                'Temp Read Blocks': 0,
                'Temp Written Blocks': 0,
                'Plans': [
                  {
                    'Node Type': 'Hash Join',
                    'Parent Relationship': 'Outer',
                    'Parallel Aware': false,
                    'Join Type': 'Inner',
                    'Startup Cost': 18.21,
                    'Total Cost': 24.25,
                    'Plan Rows': 35,
                    'Plan Width': 72,
                    'Actual Startup Time': 0.286,
                    'Actual Total Time': 0.48,
                    'Actual Rows': 130,
                    'Actual Loops': 1,
                    'Output': ['x.indexrelid', 'c.relname', 'c.relnamespace'],
                    'Inner Unique': true,
                    'Hash Cond': '(x.indrelid = c.oid)',
                    'Shared Hit Blocks': 16,
                    'Shared Read Blocks': 0,
                    'Shared Dirtied Blocks': 0,
                    'Shared Written Blocks': 0,
                    'Local Hit Blocks': 0,
                    'Local Read Blocks': 0,
                    'Local Dirtied Blocks': 0,
                    'Local Written Blocks': 0,
                    'Temp Read Blocks': 0,
                    'Temp Written Blocks': 0,
                    'Plans': [
                      {
                        'Node Type': 'Seq Scan',
                        'Parent Relationship': 'Outer',
                        'Parallel Aware': false,
                        'Relation Name': 'pg_index',
                        'Schema': 'pg_catalog',
                        'Alias': 'x',
                        'Startup Cost': 0.0,
                        'Total Cost': 5.61,
                        'Plan Rows': 161,
                        'Plan Width': 8,
                        'Actual Startup Time': 0.011,
                        'Actual Total Time': 0.063,
                        'Actual Rows': 161,
                        'Actual Loops': 1,
                        'Output': [
                          'x.indexrelid',
                          'x.indrelid',
                          'x.indnatts',
                          'x.indnkeyatts',
                          'x.indisunique',
                          'x.indisprimary',
                          'x.indisexclusion',
                          'x.indimmediate',
                          'x.indisclustered',
                          'x.indisvalid',
                          'x.indcheckxmin',
                          'x.indisready',
                          'x.indislive',
                          'x.indisreplident',
                          'x.indkey',
                          'x.indcollation',
                          'x.indclass',
                          'x.indoption',
                          'x.indexprs',
                          'x.indpred',
                        ],
                        'Shared Hit Blocks': 4,
                        'Shared Read Blocks': 0,
                        'Shared Dirtied Blocks': 0,
                        'Shared Written Blocks': 0,
                        'Local Hit Blocks': 0,
                        'Local Read Blocks': 0,
                        'Local Dirtied Blocks': 0,
                        'Local Written Blocks': 0,
                        'Temp Read Blocks': 0,
                        'Temp Written Blocks': 0,
                      },
                      {
                        'Node Type': 'Hash',
                        'Parent Relationship': 'Inner',
                        'Parallel Aware': false,
                        'Startup Cost': 17.11,
                        'Total Cost': 17.11,
                        'Plan Rows': 88,
                        'Plan Width': 72,
                        'Actual Startup Time': 0.261,
                        'Actual Total Time': 0.261,
                        'Actual Rows': 91,
                        'Actual Loops': 1,
                        'Output': ['c.relname', 'c.oid', 'c.relnamespace'],
                        'Hash Buckets': 1024,
                        'Original Hash Buckets': 1024,
                        'Hash Batches': 1,
                        'Original Hash Batches': 1,
                        'Peak Memory Usage': 18,
                        'Shared Hit Blocks': 12,
                        'Shared Read Blocks': 0,
                        'Shared Dirtied Blocks': 0,
                        'Shared Written Blocks': 0,
                        'Local Hit Blocks': 0,
                        'Local Read Blocks': 0,
                        'Local Dirtied Blocks': 0,
                        'Local Written Blocks': 0,
                        'Temp Read Blocks': 0,
                        'Temp Written Blocks': 0,
                        'Plans': [
                          {
                            'Node Type': 'Seq Scan',
                            'Parent Relationship': 'Outer',
                            'Parallel Aware': false,
                            'Relation Name': 'pg_class',
                            'Schema': 'pg_catalog',
                            'Alias': 'c',
                            'Startup Cost': 0.0,
                            'Total Cost': 17.11,
                            'Plan Rows': 88,
                            'Plan Width': 72,
                            'Actual Startup Time': 0.011,
                            'Actual Total Time': 0.221,
                            'Actual Rows': 91,
                            'Actual Loops': 1,
                            'Output': ['c.relname', 'c.oid', 'c.relnamespace'],
                            'Filter': '(c.relkind = ANY (\'{r,m}\'::"char"[]))',
                            'Rows Removed by Filter': 320,
                            'Shared Hit Blocks': 12,
                            'Shared Read Blocks': 0,
                            'Shared Dirtied Blocks': 0,
                            'Shared Written Blocks': 0,
                            'Local Hit Blocks': 0,
                            'Local Read Blocks': 0,
                            'Local Dirtied Blocks': 0,
                            'Local Written Blocks': 0,
                            'Temp Read Blocks': 0,
                            'Temp Written Blocks': 0,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    'Node Type': 'Index Scan',
                    'Parent Relationship': 'Inner',
                    'Parallel Aware': false,
                    'Scan Direction': 'Forward',
                    'Index Name': 'pg_class_oid_index',
                    'Relation Name': 'pg_class',
                    'Schema': 'pg_catalog',
                    'Alias': 'i',
                    'Startup Cost': 0.27,
                    'Total Cost': 0.4,
                    'Plan Rows': 1,
                    'Plan Width': 72,
                    'Actual Startup Time': 0.004,
                    'Actual Total Time': 0.004,
                    'Actual Rows': 1,
                    'Actual Loops': 130,
                    'Output': ['i.relname', 'i.oid', 'i.reltablespace'],
                    'Index Cond': '(i.oid = x.indexrelid)',
                    'Rows Removed by Index Recheck': 0,
                    'Filter': '(i.relkind = \'i\'::"char")',
                    'Rows Removed by Filter': 0,
                    'Shared Hit Blocks': 390,
                    'Shared Read Blocks': 0,
                    'Shared Dirtied Blocks': 0,
                    'Shared Written Blocks': 0,
                    'Local Hit Blocks': 0,
                    'Local Read Blocks': 0,
                    'Local Dirtied Blocks': 0,
                    'Local Written Blocks': 0,
                    'Temp Read Blocks': 0,
                    'Temp Written Blocks': 0,
                  },
                ],
              },
              {
                'Node Type': 'Hash',
                'Parent Relationship': 'Inner',
                'Parallel Aware': false,
                'Startup Cost': 1.06,
                'Total Cost': 1.06,
                'Plan Rows': 6,
                'Plan Width': 68,
                'Actual Startup Time': 0.015,
                'Actual Total Time': 0.015,
                'Actual Rows': 12,
                'Actual Loops': 1,
                'Output': ['n.nspname', 'n.oid'],
                'Hash Buckets': 1024,
                'Original Hash Buckets': 1024,
                'Hash Batches': 1,
                'Original Hash Batches': 1,
                'Peak Memory Usage': 10,
                'Shared Hit Blocks': 1,
                'Shared Read Blocks': 0,
                'Shared Dirtied Blocks': 0,
                'Shared Written Blocks': 0,
                'Local Hit Blocks': 0,
                'Local Read Blocks': 0,
                'Local Dirtied Blocks': 0,
                'Local Written Blocks': 0,
                'Temp Read Blocks': 0,
                'Temp Written Blocks': 0,
                'Plans': [
                  {
                    'Node Type': 'Seq Scan',
                    'Parent Relationship': 'Outer',
                    'Parallel Aware': false,
                    'Relation Name': 'pg_namespace',
                    'Schema': 'pg_catalog',
                    'Alias': 'n',
                    'Startup Cost': 0.0,
                    'Total Cost': 1.06,
                    'Plan Rows': 6,
                    'Plan Width': 68,
                    'Actual Startup Time': 0.006,
                    'Actual Total Time': 0.01,
                    'Actual Rows': 12,
                    'Actual Loops': 1,
                    'Output': ['n.nspname', 'n.oid'],
                    'Shared Hit Blocks': 1,
                    'Shared Read Blocks': 0,
                    'Shared Dirtied Blocks': 0,
                    'Shared Written Blocks': 0,
                    'Local Hit Blocks': 0,
                    'Local Read Blocks': 0,
                    'Local Dirtied Blocks': 0,
                    'Local Written Blocks': 0,
                    'Temp Read Blocks': 0,
                    'Temp Written Blocks': 0,
                  },
                ],
              },
            ],
          },
          {
            'Node Type': 'Materialize',
            'Parent Relationship': 'Inner',
            'Parallel Aware': false,
            'Startup Cost': 0.0,
            'Total Cost': 1.03,
            'Plan Rows': 2,
            'Plan Width': 68,
            'Actual Startup Time': 0.0,
            'Actual Total Time': 0.001,
            'Actual Rows': 2,
            'Actual Loops': 130,
            'Output': ['t.spcname', 't.oid'],
            'Shared Hit Blocks': 1,
            'Shared Read Blocks': 0,
            'Shared Dirtied Blocks': 0,
            'Shared Written Blocks': 0,
            'Local Hit Blocks': 0,
            'Local Read Blocks': 0,
            'Local Dirtied Blocks': 0,
            'Local Written Blocks': 0,
            'Temp Read Blocks': 0,
            'Temp Written Blocks': 0,
            'Plans': [
              {
                'Node Type': 'Seq Scan',
                'Parent Relationship': 'Outer',
                'Parallel Aware': false,
                'Relation Name': 'pg_tablespace',
                'Schema': 'pg_catalog',
                'Alias': 't',
                'Startup Cost': 0.0,
                'Total Cost': 1.02,
                'Plan Rows': 2,
                'Plan Width': 68,
                'Actual Startup Time': 0.004,
                'Actual Total Time': 0.005,
                'Actual Rows': 2,
                'Actual Loops': 1,
                'Output': ['t.spcname', 't.oid'],
                'Shared Hit Blocks': 1,
                'Shared Read Blocks': 0,
                'Shared Dirtied Blocks': 0,
                'Shared Written Blocks': 0,
                'Local Hit Blocks': 0,
                'Local Read Blocks': 0,
                'Local Dirtied Blocks': 0,
                'Local Written Blocks': 0,
                'Temp Read Blocks': 0,
                'Temp Written Blocks': 0,
              },
            ],
          },
        ],
      },
      'Planning Time': 0.833,
      'Triggers': [],
      'Execution Time': 10.442,
    },
  ],
};
export default Sample;
